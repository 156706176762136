import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { createPlansApi, memoizePlansApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { PricingPlansBi } from '../../services/bi';
import { CommonBIDataFromFlowAPI } from '../../services/CommonBIDataFromFlowAPI';
import { apiHeaders } from '../../services/headers';
import { IEvents, TabState } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { PlanListController } from './PlanListController';
import settingsParams from './settingsParams';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI,
    controllerConfig: { setProps, wixCodeApi, appParams, config },
  } = params;

  // const baseUrl = wixCodeApi.location.baseUrl.split('/').slice(0, 3).join('/');
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const headers = apiHeaders({ Authorization: appParams.instance });
  const api = createPlansApi(headers, baseUrl);

  const componentEventHandler = createEventHandler<IEvents>(config.publicData.COMPONENT || {});

  const bi = new PricingPlansBi(flowAPI.bi!, new CommonBIDataFromFlowAPI(flowAPI));

  const analytics = new Analytics(wixCodeApi.window);
  const settings = new SettingsReader(flowAPI.settings, settingsParams);
  const pp = new PlanListController(
    setProps,
    memoizePlansApi(api),
    flowAPI,
    wixCodeApi,
    appParams,
    bi,
    analytics,
    settings,
  );

  return {
    async pageReady() {
      flowAPI.fedops.interactionStarted(PlanListInteractions.PageReady);

      componentEventHandler.on('tabState', (tabState: TabState) => pp.setProps({ tabState }));
      componentEventHandler.onReset(() => pp.setProps({ tabState: TabState.REGULAR }));

      wixCodeApi.site.onInstanceChanged((e) => (headers.Authorization = e.instance), appParams.appDefinitionId);

      flowAPI.fedops.interactionEnded(PlanListInteractions.PageReady);
      await pp.initialize();
    },
    async updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
      setProps(await pp.fetchPlans());
    },
  };
};

export default createController;
